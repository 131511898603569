import styled from 'styled-components';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

type FilterTrackProps = {
    onChevronClick: (name: string, order: 'asc' | 'desc') => void;
};

const FilterTrack: React.FC<FilterTrackProps> = ({ onChevronClick }) => (
    <StyledFilterTrack>
        <div className="name-col">
            <p>Ime</p>
            <p className="chevrons">
                <FontAwesomeIcon
                    icon={faChevronUp}
                    color="white"
                    onClick={() => onChevronClick('bank_account', 'asc')}
                />
                <FontAwesomeIcon
                    icon={faChevronDown}
                    color="white"
                    onClick={() => onChevronClick('bank_account', 'desc')}
                />
            </p>
        </div>
        <div className="prefix-col">
            <p>Prefiks</p>
            <p className="chevrons">
                <FontAwesomeIcon icon={faChevronUp} color="white" onClick={() => onChevronClick('prefix', 'asc')} />
                <FontAwesomeIcon icon={faChevronDown} color="white" onClick={() => onChevronClick('prefix', 'desc')} />
            </p>
        </div>
        <div className="number-col">
            <p>Broj fakture</p>
            <p className="chevrons">
                <FontAwesomeIcon
                    icon={faChevronUp}
                    color="white"
                    onClick={() => onChevronClick('invoice_number', 'asc')}
                />
                <FontAwesomeIcon
                    icon={faChevronDown}
                    color="white"
                    onClick={() => onChevronClick('invoice_number', 'desc')}
                />
            </p>
        </div>
        <div className="date-col">
            <p>Datum</p>
            <p className="chevrons">
                <FontAwesomeIcon
                    icon={faChevronUp}
                    color="white"
                    onClick={() => onChevronClick('invoice_date', 'asc')}
                />
                <FontAwesomeIcon
                    icon={faChevronDown}
                    color="white"
                    onClick={() => onChevronClick('invoice_date', 'desc')}
                />
            </p>
        </div>
        <div className="amount-col">
            <p>Iznos</p>
            <p className="chevrons">
                <FontAwesomeIcon
                    icon={faChevronUp}
                    color="white"
                    onClick={() => onChevronClick('value_in_rsd', 'asc')}
                />
                <FontAwesomeIcon
                    icon={faChevronDown}
                    color="white"
                    onClick={() => onChevronClick('value_in_rsd', 'desc')}
                />
            </p>
        </div>
    </StyledFilterTrack>
);

const StyledFilterTrack = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr; // Aligning with InvoiceCard columns
    padding: 5px 20px;
    background-color: var(--purple);
    color: var(--white);
    margin-bottom: 10px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: flex-start;

        p {
            margin: 0;
            font-size: 12px;
        }

        .chevrons {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                color: var(--white); // White color for chevrons
                margin-left: 8px;
                font-size: 12px; // Adjust size of chevrons
                cursor: pointer;
                transition: transform 0.2s ease-in-out; // Smooth transition for scaling

                &:hover {
                    transform: scale(1.2); // Enlarge on hover
                }
            }
        }
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 1fr 1fr 1fr;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
`;

export default FilterTrack;
