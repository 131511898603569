import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Loader from '../../../components/loader';
import ReportFilters from './filters';
import ReportsMainTable from './mainTable';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import moment from 'moment';
import ReportsClientsTable from './clientsTable';
import colors from '../../../global/colors';
import Charts from './charts';
import Button, { ButtonVariant } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { getAccessToken } from '../../../functions/auth';
import useResponsive from '../../../hooks/responsive/useResponsive';
import { useNavigate } from 'react-router-dom';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const Reports: FunctionComponent = () => {
    const { isMobile } = useResponsive();
    const { currentLang } = useLanguageStore();
    const token = getAccessToken();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [reportsData, setReportsData] = useState<ObjectKeys>([]);
    const [props, setProps] = useState<FilterProps>({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
        type: 'total',
    });
    const [activeButton, setActiveButton] = useState('charts');
    const navigate = useNavigate();

    const handleButtonClick = (button: string): void => {
        setActiveButton(button);
        if (button === 'charts') {
            navigate('/statistics/charts');
        } else if (button === 'reports') {
            navigate('/statistics/reports');
        }
    };

    useEffect(() => {
        setLoaderVisible(true);
        communication
            .getAllReports(page, props)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setReportsData(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setReportsData([]);
                }
            });
    }, [page, props]);

    const handleFilterChange = (filters: FilterProps): void => {
        setPage(1);
        setProps(filters);
    };

    return (
        <>
            <Header>
                <h1>{t('pages.reports.title').text}</h1>
            </Header>
            <TabMenu>
                <div style={{ marginLeft: '10px' }}>
                    <span
                        onClick={() => handleButtonClick('charts')}
                        className={activeButton === 'charts' ? 'active' : ''}
                        style={{ textTransform: 'uppercase' }}
                    >
                        {t('pages.reports.charts.title').text}
                    </span>
                    <span
                        onClick={() => handleButtonClick('reports')}
                        className={activeButton === 'reports' ? 'active' : ''}
                        style={{ textTransform: 'uppercase' }}
                    >
                        {t('pages.reports.charts.reports').text}
                    </span>
                </div>
            </TabMenu>
            {activeButton === 'charts' && (
                <>
                    <Charts />
                </>
            )}
            {activeButton !== 'charts' && (
                <>
                    {loaderVisible && <Loader />}
                    <PageWrapper className="page">
                        <Content>
                            <ReportFilters onFilterChange={handleFilterChange} />
                            {isMobile ? (
                                <MobileItems>
                                    {t('pages.reports.mobileText').text}
                                    <Buttons>
                                        <ButtonContainer>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={'var(--purple)'}
                                                icon
                                                onClick={async () => {
                                                    const response = await communication.downloadPdf(token, props);
                                                    // Creating new object of PDF file
                                                    const file = new Blob([response?.data], {
                                                        type: 'application/pdf',
                                                    });
                                                    const fileURL = window.URL.createObjectURL(file);
                                                    // Setting various property values
                                                    const alink = document.createElement('a');
                                                    alink.href = fileURL;
                                                    alink.download = t('pages.reports.report_pdf').text;
                                                    alink.click();
                                                }}
                                            >
                                                <FontAwesomeIcon color={colors.white} icon={faSave}></FontAwesomeIcon>
                                                {t('pages.reports.download_pdf').text}
                                            </Button>
                                        </ButtonContainer>
                                        <ButtonContainer>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={'var(--purple)'}
                                                icon
                                                onClick={async () => {
                                                    const response = await communication.downloadXlsx(token, props);
                                                    // Creating new object of PDF file
                                                    const file = new Blob([response?.data], {
                                                        type: 'application/vnd.ms-excel',
                                                    });
                                                    const fileURL = window.URL.createObjectURL(file);
                                                    // Setting various property values
                                                    const alink = document.createElement('a');
                                                    alink.href = fileURL;
                                                    alink.download = t('pages.reports.report_xlsx').text;
                                                    alink.click();
                                                }}
                                            >
                                                <FontAwesomeIcon color={colors.white} icon={faSave}></FontAwesomeIcon>
                                                {t('pages.reports.download_xlsx').text}
                                            </Button>
                                        </ButtonContainer>
                                    </Buttons>
                                </MobileItems>
                            ) : (
                                <>
                                    {props.type === 'total' ? (
                                        <>
                                            <ReportsMainTable reports={reportsData} filters={props} />
                                            {reportsData?.invoices?.data?.length > 0 &&
                                                reportsData?.invoices?.meta?.pagination?.total_pages > 1 && (
                                                    <Pagination
                                                        pageCount={reportsData?.invoices?.meta?.pagination?.total_pages}
                                                        onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                        nextLabel={`${
                                                            reportsData?.invoices?.meta?.pagination?.current_page ===
                                                            reportsData?.invoices?.meta?.pagination?.total_pages
                                                                ? ''
                                                                : '>'
                                                        }`}
                                                        previousLabel={`${
                                                            reportsData?.invoices?.meta?.pagination?.current_page === 1
                                                                ? ''
                                                                : '<'
                                                        }`}
                                                        breakLabel="..."
                                                        initialPage={
                                                            reportsData?.invoices?.meta?.pagination?.current_page - 1
                                                        }
                                                    />
                                                )}
                                        </>
                                    ) : props.type === 'client_single' && props.client_id ? (
                                        <>
                                            <ReportsClientsTable
                                                reports={reportsData}
                                                filters={props}
                                                loading={loaderVisible}
                                            />
                                            {reportsData?.invoices?.data?.length > 0 &&
                                                reportsData?.invoices?.meta?.pagination?.total_pages > 1 && (
                                                    <Pagination
                                                        pageCount={reportsData?.invoices?.meta?.pagination?.total_pages}
                                                        onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                        nextLabel={`${
                                                            reportsData?.invoices?.meta?.pagination?.current_page ===
                                                            reportsData?.invoices?.meta?.pagination?.total_pages
                                                                ? ''
                                                                : '>'
                                                        }`}
                                                        previousLabel={`${
                                                            reportsData?.invoices?.meta?.pagination?.current_page === 1
                                                                ? ''
                                                                : '<'
                                                        }`}
                                                        breakLabel="..."
                                                        initialPage={
                                                            reportsData?.invoices?.meta?.pagination?.current_page - 1
                                                        }
                                                    />
                                                )}
                                        </>
                                    ) : (
                                        <>
                                            <ReportsClientsTable
                                                reports={reportsData}
                                                filters={props}
                                                loading={loaderVisible}
                                            />
                                            {reportsData?.client?.data?.length > 0 &&
                                                reportsData?.client?.meta?.pagination?.total_pages > 1 && (
                                                    <Pagination
                                                        pageCount={reportsData?.client?.meta?.pagination?.total_pages}
                                                        onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                        nextLabel={`${
                                                            reportsData?.client?.meta?.pagination?.current_page ===
                                                            reportsData?.client?.meta?.pagination?.total_pages
                                                                ? ''
                                                                : '>'
                                                        }`}
                                                        previousLabel={`${
                                                            reportsData?.client?.meta?.pagination?.current_page === 1
                                                                ? ''
                                                                : '<'
                                                        }`}
                                                        breakLabel="..."
                                                        initialPage={
                                                            reportsData?.client?.meta?.pagination?.current_page - 1
                                                        }
                                                    />
                                                )}
                                        </>
                                    )}
                                </>
                            )}
                        </Content>
                    </PageWrapper>
                </>
            )}
        </>
    );
};
const PageWrapper = styled.div``;
const Content = styled.div`
    margin-top: 30px;
`;
const MobileItems = styled.div`
    margin-top: 30px;
    text-align: center;
    font-size: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
export const TabMenu = styled.div`
    display: flex;
    background-color: ${colors.purple};
    padding: 22px;
    color: white;
    span {
        font-size: 14px;
        cursor: pointer;
        padding: 10px;
        margin-right: 10px;
        background-color: transparent;
        text-decoration: none;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: transparent;
            transition: background-color 0.5s ease;
        }

        &.active:before {
            background-color: white;
        }
    }
`;
const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
        margin-left: 10px;
        margin-right: 10px;
    }
`;
const Buttons = styled.div`
    display: flex;
`;
export default Reports;
