import React from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';

interface BannerProps {
    onClose: () => void; // Add onClose prop for closing the banner
}

const Banner: React.FC<BannerProps> = ({ onClose }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const link = t('pages.login.banner.text').text;
    return (
        <BannerWrapper>
            <CloseButton onClick={onClose}>×</CloseButton>
            <BannerTitle>{t('pages.login.banner.title').text}</BannerTitle>
            <BannerText>
                <p dangerouslySetInnerHTML={{ __html: link }} />
            </BannerText>
        </BannerWrapper>
    );
};

const BannerWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    max-width: 600px;
    background-color: white;
    color: #9b7fe9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 auto;
    top: 20px;
    font-family: Arial, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        max-width: 300px;
        padding: 10px;
        font-size: 14px;
    }
`;

const BannerTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 10px;
    text-align: justify;
    margin-top: 10px;
`;

const BannerText = styled.p`
    p {
        font-size: 14px;
        text-align: left;
        font-weight: bold;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: black;

    &:hover {
        color: #6a4d9e;
    }
`;

export default Banner;
