import React, { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useAllUsersStore from '../../../store/admin/allUsers';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import SelectComponent from '../../../components/select';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';
import { formatTwoDecimals } from '../../../functions/format';
import { usePasswordProtect } from '../../../hooks/passwordProtect';
import ConfirmModal from '../../../components/confirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import NewTransactionModal from './newTransaction';
import AddPayment from './addPayment';
import EditTransactionModal from './editTransaction';

const Transactions: FunctionComponent = () => {
    usePasswordProtect(['/admin/transactions']);
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const { allUsers } = useAllUsersStore();

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [addTransactionModal, setAddTransactionModal] = useState(false);
    const [editTransactionModal, setEditTransactionModal] = useState(false);

    const [addCustomTransactionModal, setAddCustomTransactionModal] = useState(false);

    const [page, setPage] = useState('1');
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');

    const [selectedType, setSelectedType] = useState<string>('');
    const [transactions, setTransactions] = useState<ObjectKeys>([]);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [packages, setPackages] = useState<ObjectKeys[]>([]);

    const [cart, setCart] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        status: '',
        total_price: 0,
        type: 'package',
        payment_type: 'transfer',
        note: '',
        is_upgrade: false,
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    const [selectedUserInfo, setSelectedUserInfo] = useState<ObjectKeys>({});

    const today = new Date();

    const [resendModal, setResendModal] = useState(false);
    const [clickedCartId, setClickedCartId] = useState('');

    const handleResendFiscalBill = (id: string): void => {
        setClickedCartId(id);
        setResendModal(true);
    };

    const generateFiscalBill = (): void => {
        setLoaderVisible(true);
        communication
            .resendFiscalBill(clickedCartId)
            .then(() => {
                updateData();
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                console.error(error);
                updateData();
                setLoaderVisible(false);
            });
    };

    const types = [
        { value: 'all', label: 'All' },
        { value: 'transfer', label: 'Transfer' },
        { value: 'credit_card', label: 'Credit Card' },
    ];

    const getAllPackages = (): void => {
        setLoaderVisible(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                setPackages(response.data.data);
                setCart({
                    ...cart,
                    items: cart.items.map((prev: ObjectKeys) => ({
                        ...prev,
                        id: response.data.data[0].id,
                        price: response.data.data[0].monthly,
                        months: response.data.data[0].name === 'Basic' ? 1 : 6,
                    })),
                });
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        setSelectedUserId(userBOid);
    }, [userBOid]);

    const updateData = async (): Promise<void> => {
        setLoaderVisible(true);
        if (selectedUserId.length > 0) {
            try {
                await communication
                    .getFilteredTransaction(selectedUserId, selectedType, page)
                    .then((res: ObjectKeys) => {
                        if (res.status === 200) {
                            getUserById(selectedUserId);
                            setTransactions(res?.data);
                            setLoaderVisible(false);
                        } else {
                            setLoaderVisible(false);
                        }
                    });
            } catch (err: any) {
                setErrorMessage(err?.response?.data?.message);
                setOpenErrorModal(true);
                setLoaderVisible(false);
            }
        } else {
            try {
                await communication.getAllTransactions(page, selectedType).then((response: ObjectKeys) => {
                    if (response.status === 200) {
                        setTransactions(response?.data);
                        setLoaderVisible(false);
                    } else {
                        setLoaderVisible(false);
                    }
                });
            } catch (err: any) {
                setErrorMessage(err?.response?.data?.message);
                setOpenErrorModal(true);
                setLoaderVisible(false);
            }
        }
    };

    const getUserById = (id: string): void => {
        communication
            .getUsersById(id)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setSelectedUserInfo(res.data.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setSelectedUserInfo({});
                    toast.error('Korisnik sa unetim ID-em ne postoji');
                }
            });
    };

    useEffect(() => {
        updateData();
    }, [page, selectedType]);

    useEffect(() => {
        setPage('1');
        updateData();
        if (selectedUserId) {
            getUserById(selectedUserId);
        }
    }, [selectedUserId]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const compareDates = (dateStr1: string, dateStr2: string): boolean => {
        if (!dateStr1 || !dateStr2) return false;
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Convert dates to ISO strings (UTC timezone) for accurate comparison
        const isoDate1 = date1.toISOString();
        const isoDate2 = date2.toISOString();

        // Compare the ISO date strings
        return isoDate1 === isoDate2;
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {resendModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setResendModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setResendModal(false);
                            generateFiscalBill();
                        }}
                        close={() => {
                            setResendModal(false);
                        }}
                        active={resendModal}
                        message={'Da li ste sigurni da zelite da izdate fiskalni racun?'}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            <PageWrapper className=" page">
                {editTransactionModal && (
                    <EditTransactionModal
                        success={() => {
                            updateData();
                            toast.success('Uspešno izmenjena transakcija');
                        }}
                        closeModal={() => {
                            setAddTransactionModal(false);
                        }}
                    />
                )}
                {addTransactionModal && (
                    <NewTransactionModal
                        t={t}
                        success={() => {
                            updateData();
                            toast.success('Uspešno kreirana transakcija');
                        }}
                        closeModal={() => {
                            setAddTransactionModal(false);
                        }}
                        packages={packages}
                    />
                )}
                {addCustomTransactionModal && (
                    <AddPayment
                        t={t}
                        success={() => {
                            updateData();
                            toast.success('Uspešno kreirana transakcija');
                        }}
                        closeModal={() => {
                            setAddCustomTransactionModal(false);
                        }}
                        packages={packages}
                    />
                )}
                <Header>
                    <h1>{t('pages.admin.transactions.title').text}</h1>
                    <div>
                        <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddCustomTransactionModal(true);
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Produži pretplatu
                        </Button>
                        <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddTransactionModal(true);
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Nova Transakcija
                        </Button>
                    </div>
                </Header>
                <SelectWrapper>
                    <div className="agency-select-wrapper">
                        <label>{t('pages.admin.users.chooseUser').text}</label>
                        <div className="select-container">
                            <SearchCustomComponent
                                value={allUsersOptions.find((v) => v.value === userBOid)}
                                allUsersOptions={allUsersOptions}
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    useUserStore.setState({ userBOid: data.value });
                                    setSelectedUserId(data.value);
                                }}
                                placeholder={t('pages.admin.users.chooseUser').text}
                                className="user-selector"
                            />
                        </div>
                    </div>
                    <div className="selecttype">
                        <label>TYPE: </label>
                        <SelectComponent
                            optionList={types}
                            defaultSelectedOption={types[0]}
                            handleSelectedValue={(value: string) => {
                                value === 'all' ? setSelectedType('') : setSelectedType(value);
                            }}
                        />
                    </div>
                </SelectWrapper>

                <TableContainer>
                    <StyledTable>
                        <TableHeader>
                            <tr>
                                <th>Email</th>
                                <th>PIB</th>
                                <th>Datum uplate</th>
                                <th>Datum isteka naloga</th>
                                <th>Paket</th>
                                <th>Iznos</th>
                                <th>Meseci</th>
                                <th>Način plaćanja</th>
                                {/* <th>Akcije</th> */}
                                <th>Račun</th>
                            </tr>
                        </TableHeader>
                        <TableBody>
                            {transactions?.data?.length > 0 &&
                                transactions?.data.map((transaction: ObjectKeys) => (
                                    <tr key={transaction.id}>
                                        <TableCell>{selectedUserInfo.email}</TableCell>
                                        <TableCell>{transaction.pib}</TableCell>
                                        <TableCell>
                                            {moment(transaction.transaction_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {moment(selectedUserInfo.account_expiration_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {transaction?.cart?.data?.items?.data[0]?.package?.data?.name || '-'}
                                        </TableCell>
                                        <TableCell>{formatTwoDecimals(transaction.amount.toString())}</TableCell>
                                        <TableCell>{transaction.package}</TableCell>
                                        <TableCell>{transaction?.cart?.data?.payment_type || 'trial'}</TableCell>
                                        {/* <TableCell>
                                            {transaction.cart ? null : (
                                                <ActionButtons>
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.blue}
                                                        icon={faEdit}
                                                        onClick={() => {
                                                            setSelectedTransactionId(transaction.id);
                                                            setEditTransactionModal(true);
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.danger}
                                                        icon={faTrashCan}
                                                        onClick={() => handleDeleteTransaction(transaction.id)}
                                                    />
                                                </ActionButtons>
                                            )}
                                        </TableCell> */}
                                        {/* <TableCell>{transaction?.cart?.data?.invoice_pdf_url || '-'}</TableCell> */}
                                        {transaction?.cart?.data?.invoice_pdf_url ? (
                                            <TableCell
                                                onClick={() =>
                                                    handleDownloadClick(transaction.cart.data.invoice_pdf_url)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                                            </TableCell>
                                        ) : (
                                            <>
                                                {compareDates(
                                                    today.toISOString(),
                                                    transaction?.cart?.data?.created_at,
                                                ) ? (
                                                    <TableCell
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleResendFiscalBill(transaction?.cart?.data?.id);
                                                        }}
                                                    >
                                                        <p className="billDownload">Ponovi slanje</p>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell>-</TableCell>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>

                {transactions?.data?.length > 0 && transactions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={transactions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            transactions.meta?.pagination?.current_page === transactions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${transactions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={transactions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .create-button {
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 20px;
        display: inline-block;
        vertical-align: top;
        margin: 4px 0 0 35px;
        border: 1px solid var(--purple);
        border-radius: 100px;
        cursor: pointer;
        background: var(--purple);
        color: var(--white);
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .agency-select-wrapper {
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        label {
            display: inline-block;
            color: #92a5ac;
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 10px;
        }
        .select-container {
            width: 350px;
            display: inline-block;
        }
    }
    .selecttype {
        display: flex;
        align-items: center;
        margin-left: 20px;

        label {
            text-transform: uppercase;
            line-height: 20px;
            font-size: 14px;
            margin-right: 6px;
            color: var(--gray);
        }
    }
`;
const TableContainer = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.thead`
    background-color: var(--purple);
    color: var(--white);

    th {
        font-weight: bold;
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
`;

const TableBody = styled.tbody`
    tr {
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
`;

export default Transactions;
